import broadcast from "./app/broadcast";
import dataLayer  from "./app/dataLayer";

function DomContentLoaded() {
    // var e = J.require("broadcast");
    // J.loadOrphanedModules(),
    //     e.emit("dependenciesResolved")

    // 'canvasLayer', 'labelsLayerInstance', 'particlesInstances','GlObj','DataTiler','renderCtrl', 'componentes', 'params', 'timeAnimation', 'location', 'main'
    require("./app/pluginsCtrl");
    require("./app/webView");
    broadcast.emit("DOMContentLoaded");
    // require("./app/labelsLayerInstance");
    require("./app/location")
    require("./app/params");
    require("./app/componentes");
    require("./app/pois");


}

import "../../maps-frontend/assets/css/common/web-noncritical-style.scss"
import "./leaflet-src";

// import "./app/utils.js";

import "./app/utils";
import "./app/broadcast";
import "./app/storage";
import "./app/colors";
import "./app/products";
import "./app/overlays";
import  store from "./app/store";

dataLayer.push({
    'ga4_action_name': 'visor',
    'ga4_tag_name': 'load'
})

if (! store.get("locationParsed"))
    store.set("locationMarker", false);

if ( "loading" !== document.readyState )
    DomContentLoaded();
else
    document.addEventListener("DOMContentLoaded", DomContentLoaded);
